@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
html {
  font-size: 16px;
}
* {
  margin: 0;
}
:root {
  --primary-color: #fe416c;
  --secondary-color: #d1d1d1;
  --tertiary-color: #f7f0f0;
  --forth-color: #f9c7d1;
  --font-family: "Quicksand", sans-serif;
  --fifth-color: #514f62;
  --heading-color: #ff416c;
  --white-color: #fff;
  --black-color: #000;
  --heading-font: "Outfit", sans-serif;
  --p-font: "Quicksand", sans-serif;
  --bg1-color: #e4e0e0;
  --bg2-color: #f8f7f7;
  --bg3-color: #f0eeee;
  --bg4-color: #61677c;
  --soft-white: #ebe4e7;
  --bg2-footer: #d6d6d6;
  --spacing-0: 0rem;
  --spacing-1: 1rem;
  --spacing-2: 2rem;
  --spacing-4: 4rem;
  --spacing-8: 8rem;
  --spacing-12: 12rem;
  --spacing-16: 16rem;
  --spacing-20: 20rem;
  --font-small: 12px;
  --font-medium: 16px;
  --font-large: 32px;
  --font-xl: 64px;
  --rounded-sm: 8px;
  --rounded-md: 16px;
  --rounded-xl: 32px;
}

/* new css starts here */
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
html {
  font-size: 16px;
}
:root {
  --heading-color: #ff416c;
  --white-color: #fff;
  --black-color: #000;
  --heading-font: "Outfit", sans-serif;
  --p-font: "Quicksand", sans-serif;
  --bg1-color: #e4e0e0;
  --bg2-color: #f8f7f7;
  --bg3-color: #f0eeee;
  --bg4-color: #61677c;
  --soft-white: #ebe4e7;
  --bg2-footer: #d6d6d6;
  --spacing-0: 0rem;
  --spacing-1: 1rem;
  --spacing-2: 2rem;
  --spacing-4: 4rem;
  --spacing-8: 8rem;
  --spacing-12: 12rem;
  --spacing-16: 16rem;
  --spacing-20: 20rem;
  --font-small: 12px;
  --font-medium: 16px;
  --font-large: 32px;
  --font-xl: 64px;
  --rounded-sm: 8px;
  --rounded-md: 16px;
  --rounded-xl: 32px;
}
body {
}
p {
  padding: 0px;
  margin: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
}
h1,
.h1 {
}
h2,
.h2 {
}
h3,
.h3 {
}
h4,
.h4 {
}
h5,
.h5 {
}
h6,
.h6 {
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style-type: none;
}
a {
}
input {
}

.swal2-container {
  z-index: 99999999999999999999999999 !important;
}

.nav-link {
  color: var(--black-color) !important;
  text-decoration: none !important;
}
.nav-link:hover {
  color: var(--heading-color) !important;
}
.nav-item {
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-2);
}
.show {
  background-color: var(--bg3-color);
  z-index: 99999;
  padding: var(--spacing-2);
}
.form-control:focus {
  border-color: none !important;
  border: none !important;
  box-shadow: none !important;
}
/* .navbar-collapse{
  background: rgba(231, 231, 231, 0.8);
  z-index: 999999;
} */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .display-nav {
    display: none !important;
  }
  .category-list h3 {
    font-size: 14px;
    font-weight: bolder;
  }
  .category-list-1 h3 {
    font-size: 14px;
    font-weight: bolder;
  }
  .category-list-2 h3 {
    font-size: 14px;
    font-weight: bolder;
  }
  .category-list-li {
    line-height: 2;
  }
  .category-list-p {
    font-size: 8px;
  }
  .nav-categories {
    overflow-x: scroll !important;
  }
  .show {
    position: absolute;
    top: 80%;
    left: 1%;
  }
  .nav-input {
    padding-top: 0px !important ;
    padding-bottom: 0px !important ;
    padding-left: 30px !important;
  }
  .search-icon {
    left: 12% !important;
  }
  .child-cart-product {
    margin: 6px var(--spacing-0);
  }
  .cart-cart {
    margin: var(--spacing-1) var(--spacing-0);
  }
  .proceed-btn {
    text-align: center !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .display-nav {
    display: none !important;
  }
  .nav-categories {
    overflow-x: scroll !important;
  }
  .nav-input {
    padding-top: 0px !important ;
    padding-bottom: 0px !important ;
    padding-left: 30px !important;
  }
  .search-icon {
    left: 22% !important;
  }
  .child-cart-product {
    margin: 6px var(--spacing-0);
  }
  .cart-cart {
    margin: var(--spacing-1) var(--spacing-0);
  }
  .proceed-btn {
    text-align: center !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .child-cart-product {
    margin: 6px var(--spacing-0);
  }
  .cart-cart {
    margin: var(--spacing-1) var(--spacing-0);
  }
  .proceed-btn {
    text-align: center !important;
  }
  .display-nav {
    display: none !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .display-nav {
    display: none !important;
  }
}
/* Navbar */
.nav-linear-bg {
  background: linear-gradient(90deg, #f49aaf 0%, #a3fcfc 100%);
  height: 55px;
  width: 100%;
}
.half-color {
  color: linear-gradient(90deg, gold 50%, var(--bg2-footer) 50%);
}
.navbar {
  box-shadow: none;
  border-bottom: solid 2px var(--bg1-color);
  padding-top: 0.5625rem;
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
  height: 70px;
  font-family: var(--p-font);
  font-weight: bold;
  color: var(--heading-color) !important;
  background-color: var(--bg2-color) !important;
}
.nav-logo {
  object-fit: contain;
  height: 45px;
}
::placeholder {
  color: #ababab !important;
  opacity: 1; /* Firefox */
}
.nav-input {
  border-radius: var(--rounded-md);
  background-color: var(--bg1-color) !important;
  position: relative;
  padding: var(--spacing-0) var(--spacing-4);
}
.nav-btn {
  background-color: var(--heading-color);
  border: none;
  font-size: var(--font-medium);
  color: var(--bg1-color);
  padding: 4px 20px;
  border-radius: var(--rounded-sm);
  opacity: 70%;
  width: 136px;
}
.search-icon {
  position: absolute;
  left: 67%;
  color: #ababab !important;
  padding-top: 4px;
}
.nav-main-categories {
  border-bottom: solid 2px var(--bg1-color);
  background-color: var(--bg2-color);
}
.nav-categories {
  font-family: var(--p-font);
  display: flex;
  justify-content: center;
  overflow-x: auto;
  gap: var(--spacing-2);
  font-size: var(--font-medium);
  opacity: 70%;
  font-weight: bold;
}
.nav-list {
  /* display: inline-block;
  padding: var(--spacing-0) var(--spacing-2); */
  /* padding: var(--spacing-0) var(--spacing-1); */
  cursor: pointer;
}
.nav-list p {
  margin: 6px 0;
}
.category-list {
  color: var(--black-color);
  padding: var(--spacing-1);
  background: var(--bg2-color);
  filter: grayscale(70%);
  border-radius: 8px 0px 0px 8px;
}
.category-list-2 {
  color: var(--black-color);
  padding: var(--spacing-1);
  background: var(--bg2-color);
  filter: grayscale(70%);
}
.category-list-1 {
  color: var(--black-color);
  padding: var(--spacing-1);
  background: var(--bg2-footer);
  filter: grayscale(70%);
}
.category-list h3 {
  font-size: 20px;
  font-weight: bolder;
}
.category-list-1 h3 {
  font-size: 20px;
  font-weight: bolder;
}
.category-list-2 h3 {
  font-size: 20px;
  font-weight: bolder;
}
.category-list-li {
  line-height: 2;
}
.category-list-p {
  font-size: 14px;
}
.make-up {
  padding: var(--spacing-0) 28px;
}
.make-up-child {
  display: none;
  z-index: 99999 !important;
}
.nav-list:hover .make-up-child {
  display: block;
  position: absolute;
}
.slick-slider {
  z-index: -1 !important;
}
.custom-slick-slider {
  z-index: 9999 !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: var(--heading-color) !important;
}

/* Master Banner */
.master-banner-main {
}
.master-banner-main img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.sec-banner-img {
  z-index: 999999 !important;
}
.slick-next {
  right: -5px !important;
  z-index: 9999 !important;
}
.slick-prev {
  left: -5px !important;
  z-index: 9999 !important;
}
.sec-carousel-main {
  position: relative;
  /* padding: var(--spacing-1) var(--spacing-0); */
  /* background: var(--soft-white); */
  /* text-align: center !important; */
}
.sec-banner-img img {
  height: 180px;
  width: 100%;
  object-fit: cover;
  z-index: 99999;
}
.slider-prev {
  position: absolute;
  top: 34%;
  left: 6%;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  font-size: 16px;
  padding: 12px 20px;
  background-color: var(--soft-white);
}

.slider-prev:hover {
  cursor: pointer;
}

.slider-next {
  position: absolute;
  top: 34%;
  left: 92%;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  font-size: 16px;
  padding: 12px 20px;
  background-color: var(--soft-white);
}

.slider-next:hover {
  cursor: pointer;
}

.main-title {
  text-align: center;
}
.main-title h2 {
  color: var(--heading-color);
  font-weight: 700;
  font-size: var(--font-large);
}
.mini-title {
  text-align: center;
  font-family: var(--heading-font);
  margin: var(--spacing-1) var(--spacing-0);
}
.mini-title h4 {
  color: var(--heading-color);
}
.section {
  padding: var(--spacing-1) var(--spacing-0);
  background-color: var(--bg2-color);
}
.section1 {
  padding: var(--spacing-1) var(--spacing-0);
  background-color: var(--bg2-color);
}
.hot-deal-container {
  /* text-align: center !important; */
}
.hot-deal-card {
  padding: var(--spacing-1) var(--spacing-0);
}
.popular-container {
  margin-top: var(--spacing-2);
}
.popular-img-card {
  margin-bottom: 22px;
}
.popular-img-card img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.just-arrive-main {
  height: 450px;
  background: linear-gradient(95.43deg, #53a3cf 1.51%, #77b4d7 90.06%);
  border-radius: 8px;
  text-align: center;
}
.just-arrive-img {
}
.just-arrive-img img {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.just-arrive-heading {
  background-color: var(--white-color);
  color: var(--black-color);
  text-align: center;
  padding: 8px var(--spacing-2);
  border-radius: 99px;
  margin-top: var(--spacing-2);
  display: inline-block;
}
.just-arrive-heading h3 {
  font-weight: 600;
  font-family: var(--heading-font);
  margin-bottom: 0px !important;
}
.main-card {
  margin: var(--spacing-1) var(--spacing-1);
  position: relative;
  background-color: var(--white-color);
  padding: var(--spacing-1);
  font-family: var(--heading-font);
  /* width: 280px; */
}
.card-image {
}
.card-image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.card-title {
  /* opacity: 70%; */
  color: var(--black-color);
  font-weight: 500;
}
.card-price {
  color: var(--heading-color);
  margin-bottom: var(--spacing-1);
}
.flex-end {
  color: var(--heading-color);
  font-weight: bold;
}
.card-btn {
}
.card-btn button {
  border: none;
  border-radius: var(--spacing-1);
  background-color: var(--heading-color);
  color: var(--bg2-color);
  padding: 4px var(--spacing-1);
  font-size: 14px;
}
.card-icon {
  color: var(--heading-color);
}
.d-flex-btn {
  display: flex;
  justify-content: space-between;
}
.del-price {
  color: var(--black-color);
  font-size: var(--font-small);
  margin-left: 4px;
  opacity: 70%;
}
.card-discount {
  position: absolute;
  top: 3%;
  border-radius: var(--rounded-sm);
  background-color: gold;
  color: var(--black-color);
  font-size: 12px;
  padding: 4px var(--spacing-1);
}
.total-rating {
  margin-left: 4px;
}
.gold {
  color: gold;
}
.non-gold {
  color: var(--bg2-footer);
}
.card-star {
  opacity: 80%;
  margin-left: 4px;
  margin-right: 4px;
  font-size: var(--font-small);
}
.main-card-content {
  background-color: var(--white-color);
  padding-top: var(--spacing-1);
}
.lookout-card {
  margin-bottom: var(--spacing-4);
}
.forever-img {
  text-align: center;
}
.forever-img img {
  height: 300px;
  width: 100%;
  object-fit: contain;
}
.social-media-main {
  margin: var(--spacing-2) var(--spacing-0);
}
.social-media-icon {
}
.social-media-icon img {
  height: 40px;
}
.get-app {
  background-color: var(--bg4-color);
  color: var(--white-color);
  padding: var(--spacing-1) var(--spacing-0);
  font-family: var(--heading-font);
}
.mob-image {
}
.mob-image img {
  height: 20px;
  margin-top: 4px;
}
.follow-head {
  padding-top: 6px;
  font-size: var(--font-medium);
  padding-left: var(--spacing-1);
}
.get-on-img {
  margin-top: var(--spacing-1);
}
.get-on-img img {
  height: 40px;
  width: 100%;
  object-fit: contain;
  margin-right: var(--spacing-2);
}
.follow-contact {
  margin-top: var(--spacing-1);
}
.intro-footer-main {
  background-color: var(--bg2-footer);
  padding: var(--spacing-2) var(--spacing-4);
  font-family: var(--heading-font);
}
.intro-content h3 {
  font-size: 22px;
}
.intro-content {
  text-align: center;
}
.intro-content img {
  height: 40px;
}
.delivery-footer-main {
  font-family: var(--heading-font);
}
.delivery-footer-content {
  margin: var(--spacing-1) var(--spacing-0);
}
.delivery-footer-content h5 {
  line-height: 0.8;
  font-size: var(--font-medium);
  font-weight: bold;
}
.final-footer-main {
  background-color: var(--heading-color);
  color: var(--bg2-color);
  font-family: var(--heading-font);
  padding: var(--spacing-2) var(--spacing-0);
}

/* prpduct detail */
.product-detail-main {
  background-color: var(--bg2-color);
  padding: var(--spacing-4) var(--spacing-0);
  font-family: var(--heading-font);
}
.product-detail-content {
}
.product-route {
  opacity: 80%;
  margin-bottom: var(--spacing-2);
}
.product-image {
}
.product-image img {
  height: 400px;
  width: 400px;
  object-fit: contain;
  border-radius: 20px;
}
.product-detail-intro {
  margin-left: var(--spacing-2);
}
.product-brand-title {
}
.product-brand-name {
}
.product-star {
  color: gold;
}
.product-price {
  margin: var(--spacing-1) 0px;
}
.delete-price {
  font-size: 16px;
  margin-left: var(--spacing-1);
}
.product-color {
  margin: 4px var(--spacing-0);
}
.black {
  background-color: var(--black-color);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;
}
.yellow {
  background-color: yellow;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;
}
.red {
  background-color: red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;
}
.green {
  background-color: green;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;
}
.product-size {
  margin: var(--spacing-1) var(--spacing-0);
}
.size-size {
  background-color: var(--bg2-footer);
  color: var(--black-color);
  padding: 4px 12px;
  cursor: pointer;
}
.product-try {
}
.product-coupon {
  background-color: var(--heading-color);
  color: var(--bg2-color);
  text-align: center;
  padding: var(--spacing-1) 0px;
  margin-bottom: var(--spacing-1);
  border-radius: 10px;
}
.product-btn {
}
.product-btn button {
  width: 130px;
  border: none;
  height: 40px;
  border-radius: 10px;
  color: var(--bg2-color);
  background-color: var(--heading-color);
}
.product-description {
  color: var(--bg2-color);
  background-color: var(--bg4-color);
  padding: var(--spacing-2);
  margin-top: var(--spacing-1);
}
.product-description p {
  line-height: 1.2;
}
.rating-section {
  background-color: var(--bg4-color);
  color: var(--bg2-color);
  margin-top: var(--spacing-1);
  padding: var(--spacing-2);
}
.rating-main {
  margin-right: var(--spacing-1);
}
.rating-count {
  margin: 16px var(--spacing-0);
}
.overall-rating {
  opacity: 80%;
  font-size: var(--font-medium);
}
.review-section {
  background-color: var(--bg4-color);
  margin: var(--spacing-1) var(--spacing-0);
  padding: var(--spacing-2);
  color: var(--bg2-color);
}
.review-card {
  background-color: var(--white-color);
  font-style: var(--heading-font);
  color: var(--black-color);
  padding: var(--spacing-1);
  margin: var(--spacing-1) var(--spacing-0);
}
.verified-text {
  color: green;
  font-weight: bold;
}
.like-icon {
  color: blue;
  margin-right: var(--spacing-1);
}
.review-product-name {
  opacity: 80%;
}
.addToCart-main {
  padding: var(--spacing-4) var(--spacing-0);
  font-family: var(--heading-font);
}
.cart-cart {
  height: 404px;
  overflow-y: scroll;
  border-radius: var(--spacing-1);
}
.checkout-cart {
  height: 404px;
  background-color: var(--bg2-color);
  padding: var(--spacing-2);
  border-radius: var(--spacing-1);
  border-radius: var(--spacing-1) !important;
  margin-bottom: var(--spacing-2);
}
.proceed-btn {
  text-align: end;
  margin: var(--spacing-2) var(--spacing-0);
}
.proceed-btn button {
  background-color: var(--heading-color);
  color: var(--white-color);
  border: none;
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: 8px;
}
/* .cart-cart::-webkit-scrollbar {
  display: none;
} */
.cart-products {
  background-color: var(--bg2-color);
  padding: var(--spacing-2);
}

.parent-cart-product {
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-content: center; */
}
.child-cart-product {
  flex: 1 0 33.33%;
  /* height: 100px; */
  /* text-align: center;
  box-sizing: border-box; */
}
.cart-text {
  color: var(--black-color);
  margin-left: var(--spacing-1);
}
.cart-icon {
  color: var(--heading-color);
  font-size: 14px;
}
.cart-img img {
  height: 100px;
  object-fit: cover;
}
.cart-price {
  font-size: var(--font-medium);
  color: var(--heading-color);
}
.cart-img {
}
.checkout-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  text-align: center;
}
.checkout-total {
}
.total-count {
  border-top: solid 1px var(--bg2-footer);
  margin-top: var(--spacing-1);
  padding-top: var(--spacing-1);
}
.coupon-section {
  margin-top: var(--spacing-1);
}
.coupon-input {
  margin: var(--spacing-1) var(--spacing-0);
}
.coupon-input input {
  width: 100%;
  border: none;
  padding: var(--spacing-1);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.apply-coupon-btn {
  text-align: end;
}
.apply-coupon-btn button {
  border: none;
  background-color: var(--black-color);
  color: var(--white-color);
  padding: 4px var(--spacing-2);
}
.checkout-title {
  margin-bottom: var(--spacing-1);
}
.auth-main {
  background-color: var(--bg2-color);
  font-family: var(--heading-font);
  text-align: center;
  padding: var(--spacing-4);
}
.auth-main2 {
  background-color: var(--bg2-color);
  font-family: var(--heading-font);
  text-align: center;
  padding: var(--spacing-1);
}
.auth-title {
  margin-bottom: var(--spacing-1);
  line-height: 0.2;
}
.auth-title h3 {
}
.auth-title p {
}
.auth-form {
  background-color: var(--bg4-color);
  padding: var(--spacing-2);
  color: var(--white-color);
  border-radius: var(--spacing-1);
}
.auth-input {
  margin: var(--spacing-0) var(--spacing-0) var(--spacing-1);
}
.auth-input input {
  border: none;
  padding: 4px var(--spacing-1);
}
.auth-input label {
}
.main-btn {
  margin-top: var(--spacing-1);
}
.main-btn button {
  border: none;
  padding: 4px var(--spacing-2);
  background-color: var(--heading-color);
  color: var(--bg2-color);
  border-radius: 8px;
}
.payment-list {
  border-radius: var(--spacing-1);
}
.checkout-routing {
  margin: var(--spacing-1) var(--spacing-2);
}
.card-pay-input {
  width: 100%;
  border: none;
  margin-top: 6px;
  padding: 6px var(--spacing-1);
  background-color: #d9d9d9;
  border-radius: 10px;
}
.card-pay-input:focus {
  outline: none !important;
}
.pay-btn {
  margin-top: var(--spacing-1);
}
.pay-btn button {
  width: 100%;
  border: none;
  background-color: var(--heading-color);
  color: var(--white-color);
  padding: 6px var(--spacing-0);
  border-radius: 10px;
}
.pay-btn button:hover {
  background-color: var(--bg4-color);
}

.search-filter {
  /* display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: var(--spacing-1);
  justify-content: center; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.search-filter-main {
  margin: var(--spacing-1);
  flex: 1;
  min-width: 200px;
}
.btn-btn {
  text-align: center;
}
.btn-btn button {
  border: none;
  padding: 4px var(--spacing-2);
  background-color: var(--heading-color);
  color: var(--white-color);
  border-radius: 10px;
}
.btn-btn button:hover {
  background-color: var(--bg4-color);
}
.search-result-main {
  font-family: var(--heading-font);
  /* padding: var(--spacing-2) var(--spacing-0); */
}
.text-center {
  margin: var(--spacing-1) var(--spacing-0);
}
.text-center input {
  width: 60%;
}
.faq-main {
  font-family: var(--heading-font);
  margin: var(--spacing-1) var(--spacing-0);
}
.accordion-button:not(.collapsed) {
  color: var(--heading-color);
  background-color: var(--bg2-color);
  font-weight: bold;
}
.auth-link {
  color: var(--soft-white);
  text-decoration: underline;
}
.mt-4 h4 {
  font-size: var(--font-medium);
}
.auth-link:hover {
  color: var(--heading-color);
}
.profile-main {
  font-family: var(--heading-font);
}
.profile-category div {
  cursor: pointer;
}
.profile-routing {
  background-color: var(--bg4-color);
  padding: var(--spacing-1);
  margin: var(--spacing-2) var(--spacing-8);
  box-shadow: 10px var(--bg2-footer);
  color: var(--bg3-color);
  font-family: var(--heading-font);
}
.profile-content {
}
.profile-content-img {
  text-align: center;
  padding: var(--spacing-1) var(--spacing-0);
}
.profile-content-img img {
  height: 300px;
  width: 70%;
  object-fit: cover;
  border-radius: var(--rounded-md);
}
.profile-content-info {
  padding: var(--spacing-0) var(--spacing-0);
}
.profile-content-info div {
  margin: var(--spacing-1) var(--spacing-0) var(--spacing-1);
}
.profile-content-info div > strong {
}
.profile-content-info div > span {
}
.status {
  font-size: 30px;
  margin: 2px 2px 0 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 10px;
}
.select {
  box-shadow: none;
  color: #4a4a4a;
  font-weight: 500;
  cursor: pointer;
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.5em - 1px) 1em;
  text-align: center;
  white-space: nowrap;
}
.option {
  text-align: center !important ;
}

/* new css ends here */
h1,
h2,
h5 {
  text-transform: capitalize;
  margin-bottom: 0 !important;
}
a {
  color: white !important;
}
p {
  margin-bottom: 0 !important;
}
ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.nav-link {
  padding: 0 !important;
  color: black;
}
.link-link {
  color: var(--fifth-color);
  font-weight: bold;
}
.link-link:hover {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 16px;
}
.top-padding {
  padding-top: 76px !important;
}
.link-to {
  text-decoration: none !important;
}
.accordion-main {
  margin: 36px 0;
}

.carousel-img {
  height: 650px !important;
  width: 100%;
  object-fit: cover !important;
}
.carousel-topic {
  color: black !important;
  font-size: 26px !important;
  font-family: var(--font-family);
}
.carousel-control-prev-icon:after {
  font-weight: none !important;
  font-family: none !important;
  content: none !important;
}
.carousel-control-next-icon:after {
  font-weight: none !important;
  font-family: none !important;
  content: none !important;
}
/* .carousel-control-prev-icon:after{
    
}*/
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  background-color: var(--primary-color);
  padding: 12px 11px !important;
  border-radius: 50%;
  opacity: 100% !important;
}
.nav-sublist-sub {
  font-family: var(--font-family);
}
.nav-width {
  /* width: 212px !important; */
}
.subMenu-title {
  width: 400px;
}
.manage {
  margin: 16px 0;
}
/*add to cart */
.upper_heading {
  text-align: center;
  margin: 8px 0 16px;
}
.cart-section {
  margin-top: 30px;
}
.cart-main-back {
  background-color: var(--tertiary-color);
  padding: 0 0 36px;
  font-family: var(--font-family);
  text-align: center;
}
.unit-price {
  width: 120px !important;
}
.product-thumbnail {
  width: 150px;
  overflow: hidden;
  height: 120px;
}

.product-thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.table .product-name a {
  color: black;
  text-decoration: none;
}

.table {
  border: 1px solid #ebebeb;
}

.quantity .wishlist {
  margin-top: 0;
}

.action i {
  margin-right: 20px;
  font-size: 20px;
}

.cart-buttons button {
  background: #f2f2f2;
  color: black;
  font-size: 15px;
}

.cart-buttons button:hover {
  background-color: #4fb68d;
}

.cart-forms {
  margin-top: 30px;
}

.first-cart-forms {
  border: 1px solid #ebebeb;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: justify;
}

.first-cart-forms-heading {
  margin-top: 20px;
  font-size: 20px;
  padding-left: 20px;
}

.first-cart-forms-heading h5 {
  margin-bottom: 20px;
}

.first-cart-forms-heading p {
  font-size: 14px;
}

.first-cart-forms .first-cart-forms-heading .form-group .form-control {
  width: 85%;
}

.first-cart-forms label {
  font-size: 18px;
}
.first-cart-forms-heading-total {
  margin: 8px 0;
}
.first-cart-forms-heading-total h5 {
  margin-bottom: 20px;
  font-size: 15px;
  text-align: justify;
  text-transform: capitalize;
}

.first-cart-forms-heading-total h5 span {
  float: right;
  font-size: 18px;
  padding-right: 20px;
}

.form-check label {
  font-size: 15px;
}

.form-check span {
  float: right;
  font-size: 15px;
  margin-bottom: 20px;
}

.grand-total {
  border-top: 1px solid #ebebeb;
}

.grand-total h5 {
  margin: 12px 0;
}

.grand-total span {
  float: right;
  padding-right: 20px;
}

.grand-total button {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fe416c;
  color: #fff;
}

.grand-total button:hover {
  color: #f88ea7;
}

.quantity .cart-plus-minus button {
  background-color: #fe416c;
  border: #fff;
}

/* Search Css */
.button {
  display: inline-block;
  margin: 4px 2px;
  background-color: #fe416c;
  font-size: 14px;
  padding-left: 32px;
  padding-right: 32px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  color: black;
}

.search-container {
  position: relative;
  display: inline-block;
  margin: 4px 2px;
  height: 50px;
  width: 50px;
  vertical-align: bottom;
  left: 500px;
}

.mglass {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.searchbutton {
  position: absolute;
  font-size: 22px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: #fe416c;
  color: #fff;
}

.search {
  position: absolute;
  left: 49px;
  background-color: #fff;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  border: 1px solid #fe416c;
}

.search:focus {
  width: 363px;
  padding: 0 16px 0 0;
}

.expandright {
  left: auto;
  right: 49px;
}

.expandright:focus {
  padding: 0 0 0 16px;
}

/*  Navbar css  */
/* .nav-container{
    max-width: 1280px;
}
.main-nav{
    background-color: var(--tertiary-color);
    padding: 16px 0;
    position: fixed;
    box-shadow: 0px 4px 5px -1px var(--secondary-color);
    z-index: 1000;
    font-family: var(--font-family);
}
.logo-main{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.logo-main img{
    height: 60px; 
    width: 135px;
    object-fit: contain;
}
.nav-main ul{
    padding: 0 !important;
    
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}
.nav-drop ul>li{
    list-style-type: none;
    cursor: pointer;
}
.nav-drop ul>li>p:hover{
    background-color: var(--fifth-color);
    color: #fff !important;
}
.nav-drop ul>li>p{
    font-size: 14px;
    color: #fff;
    width: 162px ;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left:8px !important;
    margin-right: 5px !important;
    padding: 4px 5px !important;
    border-radius: 10px;
    background-color: var(--primary-color);
}
.nav-icon ul>li{
    margin-top: 4px;
    margin-bottom: 4px;
    list-style-type: none;
    display: inline-block;
    margin-left:7px !important;
    padding: 4px 10px !important;
}
.navbar-icon-image{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.navbar-icon-image img{
    height: 20px;
    object-fit: cover;
}
.add-cart{
    position: relative;
}
.add-cart-num{
    position:absolute;
    top: -21%;
    background-color: var(--primary-color);
    display: inline-block;
    left: 61%;
    border-radius: 50%;
}
.add-cart-num p{
    font-size: 12px;
    padding: 1px 5px;
} */
/* Sublist CSs */
/* .drop-link:hover{
    color: var(--tertiary-color) !important;
} */
/* .dropdown-item{
    color: black;
    padding: 7px 10px 5px 9px;
}
.dropdown-item:hover{
    background-color: var(--primary-color);
    color: var(--tertiary-color) !important;
}

.dropdown-item:hover .drop-link{
    color:white !important;
}
.drop-link:hover{
    text-decoration: none;
}
.drop-link:hover .sublist-title{
    visibility: visible;
    opacity: 1;
}
.dropdown-menu {
    min-width: auto !important;
} */

/* #navbarScrollingDropdown {
    width: fit-content;
} */
/* ::-webkit-scrollbar {
    width: 15px;
  }
   */
/* Track */
/* ::-webkit-scrollbar-track {
    border-radius: 10px;
  } */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: var(--primary-color); 
    border-radius: 10px;
  } */
.sublist-title {
  visibility: hidden;
  position: absolute;
  top: 1%;
  margin-left: 10px;
  z-index: 1;
  padding: 8px 4px;
  opacity: 100% !important;
  padding-bottom: 16px;
  transition: all 0s ease;
}
.arrow-toggle {
  float: right;
  opacity: 70%;
  padding-left: 26px;
}
.sublist-title ul {
  margin: 0 !important;
  padding: 0;
}
.sublist-title ul > li {
  background-color: white;
  display: block;
  padding: 8px 16px !important;
}
.drop-list-sec {
  color: black !important;
}
.drop-list-sec:hover {
  text-decoration: none;
}
.top-image-padding {
  padding-top: 75px !important;
}
.banner-content h2 {
  color: #fff;
  font-family: var(--font-family);
}
.banner-offer p {
  background-color: var(--primary-color);
  display: inline-block;
  margin-top: 26px;
  color: #fff;
  padding: 4px 8px;
}
.banner-span {
  margin: 0 8px;
}

/*  Brand css   */
.brand-name-main {
  text-align: center;
}
.search-main-design {
  margin: 16px 0;
}
.brand-img-sort {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.brand-img-sort img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}
.main-brand {
  background-color: var(--tertiary-color);
  padding: 36px 0 0;
}
.border-row {
  margin-right: 0px !important;
}
@media only screen and (max-width: 600px) {
  .slick-next {
    right: 20px !important;
  }
  .slick-prev {
    z-index: 1;
    left: 12px !important;
  }
  .top-padding {
    padding-top: 272px !important;
  }
}
@media only screen and (max-width: 767px) {
  .brand-border {
    border: none !important;
  }
  .brand-main-design-container {
    width: 88% !important;
    margin-top: 26px;
    margin-bottom: 26px;
    margin-left: 20px;
    margin-right: 26px !important ;
    height: 1px;
  }
  .top-padding {
    padding-top: 233px !important;
  }
}
.brand-border {
  border-top: 5px dashed;
  color: var(--primary-color);
  margin-top: 20px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.brand-main-cart {
  padding: 36px 0;
}
.left-brand-container {
  position: relative;
}
.brand-main-image {
  height: 100%;
  width: 100%;
}
.brand-image {
  height: 400px;
  width: 100%;
  object-fit: cover;
  background: linear-gradient(90deg, #0000007a 0%, #64676f00 100%);
}
.brand-main-content {
  position: absolute;
  top: 60%;
  left: 5%;
  font-family: var(--font-family);
}
.brand-content-topic {
  color: #fff;
  font-size: 26px;
  text-shadow: 2px 2px var(--primary-color);
  letter-spacing: 1px;
}
.brand-content-text {
  color: white;
  opacity: 90%;
  font-size: 12px;
}
.brand-content-btn {
  background-color: var(--primary-color);
  border: none;
  color: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 6px 24px;
  margin-top: 8px;
}
.brand-content-btn:hover {
  background-color: black;
}
.brand-main-design-container {
  position: relative;
  background-color: white;
  padding: 18px;
  height: 420px;
  cursor: pointer;
  width: 253px;
  margin-bottom: 34px;
}
.must-main-design-container {
  position: relative;
  background-color: white;
  padding: 18px;
  height: 360px;
  cursor: pointer;
  width: 256px;
  margin-bottom: 34px;
}

.review-main-design-container {
  margin-top: 46px;
  position: relative;
  background-color: white;
  padding: 18px;
  height: 505px;
  cursor: pointer;
  width: 300px;
  margin-bottom: 34px;
}
.review-card {
  text-align: justify !important;
}
.review-title,
.review-message,
.review-rate {
  color: var(--primary-color);
}
.review-message {
  height: 50px;
}
.review-title span,
.review-message span,
.review-rate span {
  color: black;
}
/* .review-message span{
    
}
.review-rate span{
    
} */
@media only screen and (max-width: 1200px) {
  .brand-main-design-container {
    width: 190px;
  }
  .brand-cartimage-container {
    height: 269px !important;
  }
}
@media only screen and (max-width: 360px) {
  .brand-main-content {
    left: 0% !important;
    top: 73% !important;
  }
}
.brand-main-design-container:hover {
  /* box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%);  */
  filter: drop-shadow(5px 5px 5px var(--primary-color));
}
.cart-image-container-main {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.brand-cartimage-container {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.cart-container-content {
  font-family: var(--font-family);
  position: relative;
}
.cart-container-content p {
  margin: 6px 0;
  opacity: 90%;
}
.cart-container-price p {
  font-weight: 500;
}
.cart-container-price p > span {
  color: var(--primary-color);
}
.cart-cart-container ul {
  padding: 0;
}
.cart-cart-container ul > li {
  list-style-type: none;
  display: inline-block;
  padding-left: 35px;
}
.card-cart-btn {
  background-color: var(--primary-color);
  display: inline-block;
  color: var(--tertiary-color);
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 10px;
}
.card-cart-btn:hover {
  background-color: var(--fifth-color);
}
.cart-cart-container ul > li > p {
  color: var(--primary-color);
}
.cart-cart-container ul > li > p:hover {
  cursor: pointer;
}
.cart-container-percent {
  position: absolute;
  top: 8%;
  left: 0%;
  background-color: var(--primary-color);
  color: white;
  font-size: 12px;
  padding: 2px 8px;
}
.brandsecondcontainer-main {
  margin-top: 36px;
}

/* Category Css */
.main-category {
  background-color: var(--tertiary-color);
  padding: 15px 0 0;
}
.main-topic {
  text-align: center;
  font-family: var(--font-family);
}
.category-topic {
  margin: 16px 0 36px;
  text-align: center;
}
.category-category {
  text-align: center !important;
  font-family: var(--font-family);
  /* width: 164px !important;
    margin-left: 51px;
    padding: 36px 0; */
}
.sort-category-image {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.sort-category-image img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}
.category-category-p {
  font-size: 20px;
  color: black;
}
@media only screen and (max-width: 360px) {
  .slick-next {
    right: 20px !important;
  }
  .top-padding {
    padding-top: 172px !important;
  }
  .slick-prev {
    z-index: 1;
    left: 10px !important;
  }
  .right-brand-container {
    margin-left: 8px;
    margin-top: 25px;
  }
  .brand-main-design-container {
    margin-top: 24px;
    margin-bottom: 18px;
  }
  .brand-main-design-container {
    height: 430px;
    width: 90%;
  }
  .cart-cart-container p {
    margin-left: 0px !important;
  }
  .brand-border {
    border: none;
  }
  .brand-main-content {
    left: 80%;
    top: 40%;
  }
  .brand-main-image {
    width: 19rem;
  }
  .slider-cart-design {
    margin-left: 12px;
    width: 90%;
  }
}
.category-topic {
  text-align: center;
  background-color: var(--primary-color);
  border-radius: 10px;
  cursor: pointer;

  /* background-image: url(/public/images/categories/catback.png); */
}
.btn-lg:hover {
  background-color: var(--fifth-color) !important;
  color: var(--tertiary-color) !important;
}
.category-topic p {
  padding: 10px;
  color: var(--tertiary-color) !important;
  font-weight: bold;
}
.category-topic:hover {
  background-color: var(--fifth-color);
}
.category-link {
  color: var(--fifth-color) !important;
}
.category-link:hover {
  text-decoration: none;
  color: var(--fifth-color) !important;
}
.category-background img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.category-background {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.category-half-back-main {
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--forth-color) 50%,
    var(--tertiary-color) 50%
  );
}
.half-back-content {
  padding: 36px 0 16px;
  font-family: var(--font-family);
}
.category-deal {
  float: left;
}
.category-view {
  float: right;
}
.slider-category {
  padding-bottom: 73px;
}
.slick-prev:before {
  color: var(--primary-color) !important;
}
.slick-next:before {
  color: var(--primary-color) !important;
}

/* trending css */
.trending-main {
  background-color: var(--tertiary-color);
  position: relative;
}
.trending-image-main {
  height: 100%;
  /* width: 100%; */
  overflow: hidden;
  margin-bottom: -20px;
  /* box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%); */
}
.trending-image {
  height: 450px;
  width: 450px;
  object-fit: cover;
}
.trending-content {
  position: absolute;
  color: white;
  font-family: var(--font-family);
  font-size: 30px;
  margin-left: 16px;
  top: 80%;
  font-weight: bold;
  text-shadow: 2px 2px var(--primary-color);
}
.shop-back {
  background-image: url(/public/images/logo/logoback.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 91%;
  left: 29%;
  height: 52px;
  width: 40%;
  text-align: center;
}
.shop-back p {
  color: white;
  padding: 18px 0;
  font-size: 12px;
  font-family: var(--font-family);
}
.trending-main-all {
  margin: 36px 56px;
}
.trending-image-p {
  font-size: 12px;
  opacity: 80%;
}
.trending-btn-div {
  position: absolute;
  top: 10%;
}
.trending-see-all {
  text-align: center;
  padding-bottom: 36px;
  color: var(--primary-color);
  text-decoration: underline;
}
.trending-half-back-main {
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--tertiary-color) 50%,
    var(--forth-color) 50%
  );
}
.brand-day-main {
}

/*  Blog Css  */
.blog-main {
  background-color: var(--tertiary-color);
  padding: 36px 0 16px;
}
.blog-design-main {
  text-align: center;
  background-color: #fff;
  padding: 18px;
  margin: 16px 0 16px;
}
.blog-design-main:hover {
  filter: drop-shadow(3px 3px 3px var(--primary-color));
}
.blog-design-main-image {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.blog-design-main-image img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.blogdesign-content {
  text-align: justify !important;
  line-height: 1.5;
}
.blogdesign-text {
  margin: 8px 0;
  color: black !important;
  font-size: 12px;
  font-weight: 400 !important;
  opacity: 80%;
}
.blogdesign-content p {
  color: var(--primary-color);
  font-weight: 500;
}
.blog-banner {
}
@media only screen and (max-width: 400px) {
  .top-image-padding {
    padding-top: 76px !important;
  }
  .top-padding {
    padding-top: 252px !important;
  }
}

.brands-img-sort {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.brands-img-sort img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}
/*  Must have css  */
.musthave-main {
  background-color: var(--tertiary-color);
  padding: 0 0 36px;
}
.must-have-cart {
  margin: 36px 0 0;
}
.Musthavedesign-main img {
  background-color: #fff;
  height: 200px;
  width: 100%;
  object-fit: cover;
  padding: 15px;
  margin: 16px 0;
}
.Musthavedesign-main {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.socialfeed-main {
  background-color: var(--tertiary-color);
  padding: 16px 0;
}
.video-main {
  margin: 26px 0;
}

/* Footer css */
.main-footer {
  background-color: var(--fifth-color);
  padding: 36px 0;
}
.footer-first-side {
  color: #ffffff;
}
.footer-content {
  text-align: justify;
  color: #ffffff;
}
.footer-logo {
  height: 100%;
  margin: 36px 0;
  width: 100%;
  overflow: hidden;
}
.footer-logo img {
  height: 100px;
  width: 50%;
  object-fit: contain;
}
.footer-icon ul {
  padding: 0;
}
.footer-icon ul > li {
  list-style-type: none;
  display: inline-block;
  margin: 0 16px 0 0;
  font-size: 24px;
}
.footer-icon div {
  margin-top: 26px;
  opacity: 60%;
  font-size: 12px;
}
.footer-topic {
  font-size: 22px;
  text-decoration: underline;
}
.footer-ul-li ul {
  padding: 0;
  margin-top: 18px;
}
.footer-ul-li ul > li {
  list-style-type: none;
  line-height: 2.6;
  font-size: 14px;
  opacity: 80%;
}
.footer-subscription-detail {
  margin-top: 18px;
  font-size: 14px;
  opacity: 80%;
  text-align: justify;
}
.footer-input {
  text-align: justify;
  padding: 26px 0;
}
.footer-input input {
  border: none;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 10px 16px;
}
.footer-input button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 15px;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 16px;
}
.footer-payment-method {
  text-align: justify;
}
.footer-payment-method p {
  font-size: 22px;
}
.footer-payment-method ul {
  padding: 0;
}
.footer-payment-method ul > li {
  display: inline-block;
  list-style-type: none;
  margin-right: 10px;
}
.footer-payment-method ul > li > div {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.footer-payment-method ul > li > div > img {
  height: 40px;
  width: 100px;
  object-fit: cover;
}

/* Detail Page css */
.detail-page-main {
  background-color: var(--tertiary-color);
  padding: 36px;
  font-family: var(--font-family);
}
marquee {
  background-color: var(--primary-color);
  width: 100%;
  padding: 8px 0;
  color: var(--tertiary-color);
}
.detail-sec-main {
  margin-top: 36px;
  margin-bottom: 36px;
}
.detail-left-content {
  position: relative;
}
.detail-image-main {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.detail-image-main img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.detail-discount {
  position: absolute;
  top: 0%;
}
.detail-discount p {
  background-color: var(--primary-color);
  padding: 4px 16px;
  color: #fff;
  font-weight: 500;
}
.detain-title {
  font-size: 40px;
}
.price-del {
  color: var(--primary-color) !important;
  margin-right: 8px;
}

.detail-right-content p {
  color: var(--primary-color);
  font-weight: bold !important;
}
.detail-right-content span {
  color: gold;
}
.detail-description {
  margin: 16px 0;
}
.detail-description strong > span {
  color: white;
  font-size: 14px;
  background-color: black;
  padding: 0px 8px;
  margin-right: 4px;
  border-radius: 50%;
}
.add-cart-btn {
  margin: 16px 0;
}
.add-cart-btn button {
  padding: 4px 16px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: 20px;
  font-size: 14px;
  font-family: var(--font-family);
}
.read-more-btn {
  border: none;
  background-color: var(--primary-color);
  padding: 4px 16px;
  color: var(--tertiary-color);
  border-radius: 15px;
  font-size: 14px;
}
.read-more-btn:hover {
  background-color: var(--primary-color);
  color: white;
}
.form-control {
  padding: 0.375rem 3.75rem !important;
}
.add-cart-btn button:hover {
  background-color: black;
}
.detail-description p {
  background-color: var(--secondary-color);
  color: black;
  padding: 12px;
  border-radius: 10px;
}
.detail-description div {
  margin-top: 8px;
}
.detail-readmore {
  color: var(--primary-color) !important;
  font-weight: 500;
}
.span-count {
  color: var(--primary-color) !important;
  font-weight: 400;
}
/* .detail-quantity{
    line-height: 30px;
} */
.detail-quantity button {
  border: none;
  background-color: none !important;
}
.quantity-main {
  margin: 16px 0;
}
.detail-review {
  margin-top: 16px;
}
.modal-body {
  padding-left: 1rem !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.image-detail-main {
  height: auto;
  width: 100%;
  position: relative;
  z-index: 2;
}
.detail-review button {
  border: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: 20px;
  padding: 4px 14px !important;
  font-size: 14px;
  font-family: var(--font-family);
}
.detail-review button:hover {
  background-color: black;
  color: white;
}
.similar-detail {
  text-align: center;
}
.similar-topic {
  font-size: 20px;
}
.similar-sub {
  text-align: justify;
  margin-top: 26px;
}

/* review css */
.review-main {
  background-color: var(--tertiary-color);
  padding: 36px 0;
}
.reviewed-main {
  padding: 26px 0;
  text-align: center;
  background-color: var(--secondary-color);
  border-radius: 20px;
  margin: 16px 26px;
}
.review-icon h3 {
  color: var(--primary-color);
  font-size: 36px;
}
.review-content {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 18px;
}
.nav-tabs .nav-link.active {
  color: var(--tertiary-color);
  background-color: var(--primary-color);
  padding: 8px 16px !important;
}
.nav-tabs .nav-link.active:hover {
  color: var(--tertiary-color) !important;
}
.nav-item button {
  padding: 8px 16px !important ;
}
.nav-item {
  /* width: 50%; */
  text-align: center !important;
}
.nav-item button:hover {
  border: none !important;
}
.nav-tabs .nav-link {
  border: none;
  width: 100%;
}
.nav-tabs {
  border-bottom: none !important;
  margin: 26px !important;
}

/*  haircare css  */
.haircare-main {
  background-color: var(--tertiary-color);
  padding: 36px 0;
}
.haircare-cart {
  margin: 26px 0;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 var(--fifth-color);
  border-radius: 20px;
  overflow: hidden;
}
.haircare-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.haircare-img img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.haircare-content {
  margin: 8px 10px;
}
.haircare-content strong {
  font-size: 16px;
  font-weight: 500;
}
.haircare-content p {
  color: gold;
}
.haircare-price {
  font-weight: 600 !important;
}
.haircare-add ul {
  padding: 0;
}
.haircare-add ul > li {
  display: inline-block;
}
.haircare-add ul > li > button {
  border: none;
  margin: 0 8px 8px 8px;
  background-color: var(--secondary-color);
  padding: 4px 36px;
  border-radius: 20px;
}
.haircare-add ul > li > p {
  color: var(--primary-color);
  margin-left: 55px;
}

/*  View all css  */
.view-all-link {
  text-decoration: none !important;
  color: black !important;
}

.view-link {
  color: black !important;
}
.view-link:hover {
  text-decoration: none;
}
.main-view-all {
  background-color: var(--tertiary-color);
  padding: 36px 0;
  font-family: var(--font-family);
}
.view-all-cart {
  margin: 26px 0;
}
.view-cart {
  margin-bottom: 26px;
}
.terms-main {
  background-color: var(--tertiary-color);
  text-align: justify;
}
.terms-div {
  margin-top: 36px;
  padding-bottom: 36px;
}

/*  Contact Us Css  */
.contact-main {
  padding: 36px 0;
  background-color: var(--tertiary-color);
}
.contact-content {
  text-align: center;
}
.option-msg {
  background-color: black;
  color: white;
}
.contact-textarea textarea {
  border: none;
  padding: 0 8px 0;
  border-radius: 10px;
}
.contact-form {
  text-align: justify;
  background-color: var(--forth-color);
  display: inline-block;
  padding: 26px;
  border-radius: 10px;
}
.contact-label {
  font-size: 16px;
  font-weight: 500;
}
.contact-msg {
  margin: 16px 0;
}
.message-type {
  height: 37px;
  border: none;
  padding: 4px 16px;
  width: 80%;
}
.contact-btn {
  text-align: center;
  margin: 16px 0 8px;
}
.contact-btn button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 4px 26px;
  border-radius: 10px;
}
.categories-main {
  background-color: var(--tertiary-color);
  padding: 36px 0;
}
.category-cart {
  margin: 26px 0;
}

/*  login css  */
.main-signin {
  background-color: var(--tertiary-color);
}
.main-login-signup {
  padding: 36px 0;
}
.main-login-form {
  text-align: center !important;
  font-family: var(--font-family);
}
.login-form {
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 10%);
  padding: 36px 0;
}
.login-input {
  margin-bottom: 26px;
}
.login-input label {
  font-family: var(--font-family);
}
.login-input input {
  width: 80%;
  border: none;
  padding: 6px;
}
.login-input textarea {
  width: 80%;
  border: none;
  padding: 6px;
}
.login-input p {
  color: red;
}
.res-error {
  background-color: var(--forth-color);
  color: red;
  text-align: center;
  border: var(--primary-color) !important;
}
.login-button button {
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
  padding: 3px 18px;
}
.login-button button:hover {
  background-color: var(--fifth-color);
}
.signup-main {
  margin: 16px 0;
}
.signup-link {
  color: var(--primary-color) !important;
  margin-left: 8px;
}
.search-main {
  background-color: var(--tertiary-color);
}
.search-input {
  text-align: center;
  padding-top: 26px;
  margin-bottom: 16px;
}
.search-input input {
  width: 88%;
  padding: 8px 6px;
  border: none;
}
.filter-main {
  font-family: var(--font-family);
  text-align: center;
  margin: 26px 0;
}
.sort-by-main select {
  border: none;
  padding: 7px 6px;
  border-bottom: 2px solid;
}
.sort-price input {
  border: none;
  border-bottom: 2px solid;
  padding: 4px 8px;
}
.search-btn {
  margin: 16px 0;
}
.search-btn button {
  border: none;
  background-color: var(--primary-color);
  padding: 4px 26px;
  color: var(--tertiary-color);
}
.search-btn button:hover {
  background-color: black;
}
.search-button {
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  border: none;
  border-radius: 10px;
  padding: 4px 26px;
  margin: 16px 0;
}
.search-button:hover {
  background-color: var(--fifth-color);
}
/*  My Profile  */
.profile-image {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.profile-image img {
  height: 90px;
  width: 90px;
  object-fit: cover;
  border-radius: 50%;
}
.profile-main {
  background-color: var(--tertiary-color);
}
.profile-form-main {
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 10%) !important;
  padding: 36px;
}
.myprofile form .btn {
  width: 100%;
}
.myprofile {
  padding: 36px 0;
}
.form-group {
  font-family: var(--font-family);
}
.profile-btn {
  background-color: var(--primary-color);
  color: #fff;
}
.profile-btn:hover {
  background-color: black;
  color: white;
}

/* my points */
.reward {
  background-color: var(--tertiary-color);
  padding: 36px 0;
}
.reward-main {
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 10%);
  padding: 26px;
}
.reward-icon {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
}
.reward-icon p {
  padding-top: 6px;
}
.rewards-point {
  margin: 0 12px;
  text-align: center;
}
.rewards-point p > span {
  font-size: 22px;
}
.rewards-point p {
  color: var(--primary-color);
  border: dashed;
  border-color: var(--primary-color);
  border-radius: 25px;
}
.forget-main {
  font-family: var(--font-family);
  background-color: var(--tertiary-color);
  padding: 26px 0;
}
.forget-form {
  padding: 26px;
  text-align: center;
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 10%) !important;
}
.forget-form form {
}
.forget-form form > input {
  border: none;
  padding: 4px 8px;
}
.forget-form button {
  margin: 16px 0 0;
  padding: 4px 16px;
  border: none;
  color: white;
  background-color: var(--primary-color);
}
.forget-form button:hover {
  background-color: black;
}
.proceed-main {
  font-family: var(--font-family);
  background-color: var(--tertiary-color);
}
.proceed-sec {
  padding-top: 46px;
}
.order-now {
  text-align: center;
}
.order-now label {
  font-size: 22px;
}
.order-now textarea {
  border-color: var(--primary-color);
  width: 100%;
  resize: none;
}
.delivery-icon p {
  color: var(--primary-color);
  padding-left: 18px;
}
.order-note {
  box-shadow: 3px 2px 12px 2px rgb(0 0 0 / 20%);
  padding: 26px;
  margin-top: 26px;
}
.pay-methods {
  margin: 36px 0;
  text-align: center;
  padding: 16px;
  box-shadow: 3px 2px 12px 2px rgb(0 0 0 / 20%);
}
.pay-methods strong {
  font-size: 26px;
}
.pay-image img {
  height: 60px;
  width: 60%;
  object-fit: cover;
}
.pay-image {
  margin: 26px 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.pay-image p {
  margin: 8px 0;
  font-size: 20px;
  font-weight: 500;
}
.order-detail-main {
  margin: 16px 0;
  box-shadow: 3px 2px 12px 2px rgb(0 0 0 / 20%);
  padding: 26px;
}
.order-detail-strong {
  text-align: center;
  font-size: 22px;
}
.rate {
  text-align: center;
}
.pay-btn {
  padding: 26px 0;
  text-align: center;
}
.pay-btn button {
  background-color: var(--primary-color);
  padding: 4px 26px;
  border: none;
  color: white;
}
.pay-btn button:hover {
  background-color: black;
}

/* wishlist */
.wish-cart {
  color: var(--primary-color) !important;
}
.offer-topic {
  text-align: center;
  font-family: var(--font-family);
  margin: 0 0 26px;
  text-transform: capitalize;
}
.offers-content {
  padding: 26px 0;
}
.banner-offer-main {
  padding-bottom: 26px;
}
.edit-profile-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}
.edit-profile-img img {
  height: 300px;
  width: 300px;
  object-fit: cover;
}
.input-file::-webkit-file-upload-button {
  display: none;
}
.input-file::before {
  content: "Change Photo";
  background-color: var(--primary-color);
  padding: 4px 8px;
  color: var(--tertiary-color);
  border-radius: 10px;
  margin-right: 4px;
}
.input-file:hover::before {
  border-color: black !important;
}
.input-file:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.profile-bar {
  text-align: center;
}
.profile-bar ul > li {
  list-style-type: none;
  line-height: 2.6;
  display: inline-block;
  padding: 8px 16px;
  margin: 4px 6px;
  cursor: pointer;
}
.bar-h2 {
  font-family: var(--font-family);
  text-align: center;
}
.products-you-like {
  padding: 36px 0 0;
}
.code-input {
  border-color: var(--primary-color);
  width: 100%;
  border-radius: 8px;
  padding: 0 12px 0;
}
.btn-col button {
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
  padding: 3px 18px;
  width: 100%;
}
.btn-col button:hover {
  background-color: var(--fifth-color);
}
.coupon-btn button {
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
  padding: 3px 18px;
  display: inline-block;
}
.coupon-btn button:hover {
  background-color: var(--fifth-color);
}
.coupon-btn {
  text-align: center;
  margin: 8px 0 0;
}
.available-coupon-main {
  padding: 36px 0 0;
  text-align: center;
}
.coupon-card-main {
  padding-bottom: 36px;
}
.coupon-card {
  background-image: url("/src/Assets/Images/coupons.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 140px;
  width: 100%;
  background-size: contain;
  color: var(--tertiary-color);
}
.coupon-card-content {
  margin-top: 38px;
}
.coupon-card h4 {
  font-size: 14px;
}
.coupon-card-content2 {
  margin-top: 46px;
}
.our-blog-main {
  padding: 36px 0;
}
.our-blog-content {
  margin-bottom: 26px;
}
.our-blog-img {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.our-blog-img img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
.our-blog-desc {
  text-align: justify !important;
  font-family: none;
  font-size: 12px !important;
  font-style: italic;
  font-family: var(--font-family);
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 12px;
}
.blogs-writer {
  margin-top: 16px;
}
.our-blog-span {
  color: var(--primary-color);
}

/*  Order History  */

table.table tr th:first-child {
  width: 300px;
}
table.table tr th:last-child {
  width: 120px;
}
table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
table.table-striped.table-hover tbody tr:hover {
  background: #f5f5f5;
}
table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}
table.table td a {
  font-weight: bold;
  color: #566787;
  display: inline-block;
  text-decoration: none;
}
table.table td a:hover {
  color: #2196f3;
}
table.table td a.view {
  width: 30px;
  height: 30px;
  color: #2196f3;
  border: 2px solid;
  border-radius: 30px;
  text-align: center;
}
table.table td a.view i {
  font-size: 22px;
  margin: 2px 0 0 1px;
}
table.table .avatar {
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}
.status {
  font-size: 30px;
  margin: 2px 2px 0 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 10px;
}
.text-success {
  color: #10c469;
}
.text-success1 {
  color: red;
}
.text-info {
  color: #62c9e8;
}
.text-warning {
  color: #ffc107;
}
.text-danger {
  color: #ff5b5b;
}
.ul-skeleton li {
  display: inline-block;
  margin-right: 6px;
}
/* .dropdown-list{
    height: 120px !important;
} */
.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary-color) !important;
  color: var(--tertiary-color);
  border: none;
}
.subMenu-ul {
  position: absolute;
  left: 1%;
}
.subMenu-li {
  color: black;
  display: block !important;
}
.flowing-text {
  position: absolute;
  font-size: 17px;
  font-weight: bold;
  color: var(--primary-color) !important;
  top: -34%;
  width: 81%;
  background-color: var(--tertiary-color);
  padding: 3px 0 0;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
  .trending-image-main {
    margin-right: 36px !important;
  }
  .category-skelton {
    margin-left: 52px !important;
  }
  .trending-main-all {
    margin: 0 16px !important;
  }
  .cart-cart-container ul > li {
    list-style-type: none;
    display: inline-block;
    padding-left: 0px;
  }
  .card-skeleton {
  }
  .top-padding {
    padding-top: 105px !important;
  }
}
@media only screen and (max-width: 1080px) and (min-width: 700px) {
  .trending-image {
    width: 75%;
  }
  .trending-main-all {
    margin: 36px 46px !important;
  }
  .brand-image {
    /* margin-left: 16px !important; */
  }
  .cart-cart-container ul > li {
    list-style-type: none;
    display: inline-block;
    padding-left: 16px;
  }
  .brand-main-design-container {
    margin-bottom: 26px !important;
  }
}
.img-offer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.img-offer img {
  height: 450px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 26px;
}
.top-padding-2 {
  margin-top: 126px;
}
.sublist-ul li {
  display: grid !important;
  box-shadow: 0px 4px 5px -1px var(--secondary-color);
}
.drop-link {
  color: black !important;
  /* position: relative; */
  overflow: hidden;
  cursor: pointer;
}
.sublist-main {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}
.sublist-title:hover + .sublist-main {
  visibility: visible;
  opacity: 1;
  font-size: 20px;
  left: 60px;
}
.edit-profile-content {
  text-align: justify;
  margin-top: 20px;
  line-height: 2.7;
}
.edit-profile-content p > span {
  font-weight: bold;
  margin-right: 12px;
}
/* input[type='file'] {
    color: rgba(0, 0, 0, 0)
  } */
.cart-image {
  height: 120px !important;
  width: 120px !important;
  object-fit: contain;
}
.css-1vooibu-MuiSvgIcon-root {
  color: gold !important;
}
.review-btn {
  text-align: center;
}
.review-btn button {
  border: none;
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  padding: 4px 12px;
  font-size: 14px;
  margin-top: 8px;
  border-radius: 10px;
}
.modal-dialog {
  margin-top: 100px;
}
.stock-p {
  color: red;
}
.css-1wnsr1i {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 400px;
  overflow: scroll;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: none !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
}
.dropdown-menu {
  border: none !important;
  border-radius: none !important;
}
.dropDown-Menu {
  height: 450px !important;
  /* overflow-y: scroll;
    overflow-x: visible !important; */
}
.td-pointer {
  cursor: pointer !important;
}

main-fallback {
  background: var(--bg);
  color: var(--fg);
  display: flex;
  font: 1em/1.5 sans-serif;
  height: 100vh;
  overflow: hidden;
}
.preloader,
.box,
.box__inner,
.box__inner div {
  transform-style: preserve-3d;
}
.preloader {
  margin: auto;
  position: relative;
  width: 12em;
  height: 24em;
}
.box,
.box__inner,
.box__inner div,
.line {
  position: absolute;
}
.box,
.box__inner div,
.line__inner {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.box,
.line__inner {
  animation-duration: var(--dur);
}
.box__inner div,
.line__inner {
  background: var(--fg);
}
/* Boxes */
.box,
.box__inner {
  width: 100%;
  height: 50%;
}
.box {
  animation-name: popOut;
  top: 50%;
  perspective: 25em;
  transform-origin: 50% 75%;
  z-index: 1;
}
.box + .box {
  animation-delay: calc(var(--dur) / -2);
}
.box__inner,
.box__inner div {
  animation-delay: inherit;
  animation-duration: inherit;
}
.box__inner {
  transform: rotateX(50deg);
  transform-origin: 50% 25%;
}
.box__inner div {
  box-shadow: 0 0 0 0.2em var(--bg) inset;
}
.box__back-flap,
.box__front-flap,
.box__front {
  width: 6em;
}
.box__left-flap,
.box__right-flap,
.box__front {
  height: 6em;
}
.box__back-flap,
.box__front-flap {
  left: 3em;
  height: 3em;
}
.box__left-flap,
.box__right-flap {
  top: 3em;
  width: 3em;
}
.box__back-flap {
  animation-name: backFlap;
  transform: rotateX(-180deg);
  transform-origin: 50% 100%;
}
.box__right-flap {
  animation-name: rightFlap;
  left: 9em;
  transform: rotateY(-179deg);
  transform-origin: 0 50%;
}
.box__front-flap {
  animation-name: frontFlap;
  top: 9em;
  transform: rotateX(180deg);
  transform-origin: 50% 0;
}
.box__left-flap {
  animation-name: leftFlap;
  transform: rotateY(179deg);
  transform-origin: 100% 50%;
}
.box__front {
  top: 3em;
  left: 3em;
  transform: rotateX(-90deg) translateY(50%) translateZ(3em);
}
/* Lines */
.line,
.line__inner {
  border-radius: 0.1em;
}
.line {
  top: 0;
  left: calc(50% - 0.1em);
  width: 0.2em;
  height: 6em;
  overflow: hidden;
  transform-origin: 50% 0.1em;
}
.line:nth-child(n + 5) {
  top: 90%;
  transform: translateY(2em);
}
.line:nth-child(3) {
  left: calc(50% - 1.1em);
}
.line:nth-child(4) {
  left: calc(50% + 0.9em);
}
.line:nth-child(5) {
  transform: rotate(-90deg) translateY(2em);
}
.line:nth-child(6) {
  transform: rotate(-45deg);
}
.line:nth-child(7) {
  transform: translateY(-0.5em);
}
.line:nth-child(8) {
  transform: rotate(45deg);
}
.line:nth-child(9) {
  transform: rotate(90deg) translateY(2em);
}
.line__inner {
  animation-duration: calc(var(--dur) / 2);
  width: 100%;
  height: 100%;
}
.line:nth-child(n + 3):nth-child(-n + 4) .line__inner {
  animation-name: falling;
}
.line:nth-child(n + 5):nth-child(-n + 9) .line__inner {
  animation-name: landing;
  transform: translateY(-100%);
}
/* Dark Mode */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: #17181c;
    --fg: #e3e4e8;
  }
}
/* Animations */
@keyframes backFlap {
  from,
  33% {
    transform: rotateX(-180deg);
  }
  41% {
    transform: rotateX(10deg);
  }
  45% {
    transform: rotateX(-27deg);
  }
  50%,
  to {
    transform: rotateX(-15deg);
  }
}
@keyframes rightFlap {
  from,
  33% {
    transform: rotateY(-179deg);
  }
  41% {
    transform: rotateY(10deg);
  }
  45% {
    transform: rotateY(-27deg);
  }
  50%,
  to {
    transform: rotateY(-15deg);
  }
}
@keyframes frontFlap {
  from,
  33% {
    transform: rotateX(180deg);
  }
  41% {
    transform: rotateX(-10deg);
  }
  45% {
    transform: rotateX(27deg);
  }
  50%,
  to {
    transform: rotateX(15deg);
  }
}
@keyframes leftFlap {
  from,
  33% {
    transform: rotateY(179deg);
  }
  41% {
    transform: rotateY(-10deg);
  }
  45% {
    transform: rotateY(27deg);
  }
  50%,
  to {
    transform: rotateY(15deg);
  }
}
@keyframes popOut {
  from,
  21% {
    transform: translateZ(1px) translateY(0) scale(0, 0);
  }
  33% {
    transform: translateZ(1px) translateY(-100%) scale(0.75, 1.5);
  }
  41% {
    transform: translateZ(1px) translateY(-100%) scale(1, 1);
  }
  42%,
  50% {
    transform: translateZ(0) translateY(-100%) scale(1, 1);
  }
  55% {
    transform: translateZ(0) translateY(-100%) scale(0.75, 1.5);
  }
  60% {
    transform: translateZ(0) translateY(0) scale(1.5, 0.75);
  }
  67% {
    transform: translateZ(0) translateY(0) scale(1, 1);
  }
  to {
    transform: translateZ(0) translateY(0) scale(0, 0);
  }
}
@keyframes falling {
  from,
  12% {
    transform: translateY(0%);
  }
  28%,
  to {
    transform: translateY(100%);
  }
}
@keyframes landing {
  from,
  17% {
    transform: translateY(-100%);
  }
  41%,
  to {
    transform: translateY(100%);
  }
}
.pay-image-main {
  text-align: center;
}

.pay-image {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.pay-image img {
  object-fit: contain;
  height: 200px;
  width: 200px;
}
.selected-p {
  font-weight: bold;
}

.deals-div {
  height: 15rem;
  width: 15rem;
  margin: 2rem 0rem;
  padding: 0rem 1rem;
}

.deals-div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: block; */
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.after {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.after .title {
  text-transform: uppercase;
  color: var(--heading-color);
}

/* .image-container:hover .after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
} */

.make-up a {
  color: black !important;
}

.make-up-child a {
  color: black !important;
}

.category-list-p a {
  color: black !important;
}

.make-up a:hover,
.make-up-child a:hover,.category-list-p a:hover {
  text-decoration: none !important;
}
